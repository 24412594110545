@import "@aws-amplify/ui-react/styles.css";

.App {
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.App nav {
  flex-basis: 160px;
  flex-shrink: 0;
  height: 100vh;
  background-color: #282828;
}

.App main {
  flex-grow: 1;
  margin-right: 10px;
}